<template>
	<w-layout px-4 row wrap>
		<w-flex md6 xs12>
			<w-layout column px-1>
				<w-text-input v-model="copy.label" :disabled="selectedProvider === null" :label="$t('bill-scrapper.automations.label')" maxlength="191" />
				<w-autocomplete
					v-model="selectedProvider"
					:items="providers"
					item-text="name"
					item-value="id"
					:label="$t('bill-scrapper.automations.connector')"
					:loading="providersLoading"
					return-object
					@change="onSelectedProviderChanged($event)"
				/>
				<w-flex grow>
					<w-warning v-if="showBetaWarning" :text="$t('bill-scrapper.automations.connector_beta_warning')" />
					<w-info v-if="showWebAuthInfo" :text="$t('bill-scrapper.automations.connector_webauth_info')" outline />
				</w-flex>
			</w-layout>
		</w-flex>
		<w-flex md6 xs12>
			<w-layout v-if="selectedProvider" column px-1>
				<w-form-builder ref="form" :key="selectedProvider.id" v-model="formData" :fields="fields" :validation.sync="isFormValid" />
				<w-flex grow text-xs-right>
					<w-btn-save :disabled="!canSave" :flat="false" :loading="loading" @click="saveConnection()" />
				</w-flex>
			</w-layout>
		</w-flex>
		<w-dialog v-model="additionalInformationsDialog" max-width="600" :title="$t('bill-scrapper.automations.additional_informations_dialog')">
			<w-layout column>
				<w-text-info
					:text="
						$t('bill-scrapper.automations.additional_informations_text', {
							name: providerName
						})
					"
				/>
				<w-form-builder v-model="formData" :fields="additionalFields" :validation.sync="isAdditionalFormValid" @submit="updateConnection()" />
			</w-layout>
			<template v-slot:actions>
				<w-flex text-xs-right>
					<w-btn-save :disabled="!isAdditionalFormValid" @click="updateConnection()" />
				</w-flex>
			</template>
		</w-dialog>
	</w-layout>
</template>
<script>
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'
export default {
	name: 'ConnectionForm',
	mixins: [BillScrapperModuleGuard],
	data: function () {
		return {
			additionalFields: [],
			additionalInformationsDialog: false,
			canSave: false,
			copy: {},
			fields: [],
			formData: {},
			isAdditionalFormValid: false,
			isFormValid: false,
			loading: false,
			providers: [],
			providersLoading: false,
			selectedProvider: null,
			value: {}
		}
	},
	computed: {
		providerName: function () {
			return this.selectedProvider?.name
		},
		showWebAuthInfo: function () {
			return this.selectedProvider?.auth_mechanism === 'webauth'
		},
		showBetaWarning: function () {
			return this.selectedProvider?.is_beta
		}
	},
	watch: {
		'copy.label': {
			handler: 'computeCanSave'
		},
		isFormValid: {
			handler: 'computeCanSave'
		}
	},
	created: function () {
		this.listProviders()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.CONNECTION_DELETED, action: this.onConnectionDeleted },
				{ event: this.events.UPDATE_CONNECTION, action: this.onUpdateConnection }
			]
		},
		computeCanSave: function () {
			return new Promise(resolve => {
				if (this.value.id) {
					this.canSave =
						(this.copy.label !== this.value.label && (Object.keys(this.formData).length === 0 || this.isFormValid)) ||
						(this.copy.label === this.value.label && this.isFormValid)
				} else {
					this.canSave = this.isFormValid
				}
				resolve()
			})
		},
		createConnection: function () {
			const accountingFirmId = this.accountingFirmId
			const vendorId = this.vendorId
			const providerId = this.selectedProvider.id
			let label = null
			if (this.copy.label) {
				label = this.copy.label
			}
			return this.service.createConnection(accountingFirmId, vendorId, providerId, label, { ...this.formData })
		},
		listProviders: function () {
			this.providersLoading = true
			return new Promise(resolve => {
				const promise = this.service.getProviders()
				resolve(promise)
			})
				.then(providers => {
					providers.forEach(provider => {
						this.providers.push(provider)
					})
				})
				.finally(() => {
					this.providersLoading = false
				})
		},
		onConnectionDeleted: function (connectionId) {
			if (this.value?.id != connectionId) {
				return
			}

			this.resetFormData()
			this.fields = this.selectedProvider.fields
			this.$refs.form.reset()
		},
		onSelectedProviderChanged: function (provider) {
			this.resetFormData()
			this.setFields(provider)
		},
		onUpdateConnection: function (connection) {
			return new Promise(resolve => {
				this.selectedProvider = this.providers.find(provider => provider.id === connection.provider.id)
				this.fields = this.selectedProvider.fields
				this.formData = {}
				this.value = connection
				this.copy = { ...connection }
				resolve()
			})
		},
		resetFormData: function () {
			this.formData = {}
			this.fields = []
			this.value = {}
			this.copy = {}
		},
		saveConnection: function () {
			this.loading = true
			return new Promise(resolve => {
				let promise
				if (this.value?.id) {
					promise = this.updateConnection()
				} else {
					promise = this.createConnection()
				}
				resolve(promise)
			})
				.then(() => {
					this.resetFormData()
				})
				.catch(error => {
					if (error.fields && error.code === 'additional-information-needed') {
						this.setAdditionalFields(error.fields)
						this.value = error.connection
						this.copy = { ...error.connection }
						this.additionalInformationsDialog = true
					} else {
						throw error
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		setAdditionalFields: function (fields) {
			this.additionalFields.splice(0, this.additionalFields.length)

			fields.forEach(field => {
				if (field.type !== 'list') {
					this.additionalFields.push({
						label: field.label,
						name: field.name,
						regex: field.regex,
						required: field.required,
						type: field.type
					})
				} else {
					this.additionalFields.push({
						items: field.values.map(val => ({
							text: val.label,
							value: val.value
						})),
						label: field.label,
						name: field.name,
						required: field.required,
						type: field.type
					})
				}
			})
		},
		setFields: function (provider) {
			this.fields.splice(0, this.fields.length)

			if (
				provider.fields?.auth_mechanisms === undefined ||
				(Array.isArray(provider.fields?.auth_mechanisms) && provider.fields.auth_mechanisms.includes(provider.auth_mechanism))
			) {
				provider.fields.forEach(field => {
					if (field.type !== 'list') {
						this.fields.push({
							label: field.label,
							name: field.name,
							regex: field.regex,
							required: field.required,
							type: field.type
						})
					} else {
						this.fields.push({
							items: field.values.map(val => ({
								text: val.label,
								value: val.value
							})),
							label: field.label,
							name: field.name,
							required: field.required,
							type: field.type
						})
					}
				})
			}
		},
		updateConnection: function () {
			const accountingFirmId = this.accountingFirmId
			const vendorId = this.vendorId
			const data = {}
			if (Object.keys(this.formData).length > 0) {
				data.connection_data = { ...this.formData }
			}
			if (this.copy.label !== this.value.label) {
				data.label = this.copy.label
			}
			return this.service.updateConnection(accountingFirmId, vendorId, this.value.id, data).then(connection => {
				this.resetFormData()
				this.additionalInformationsDialog = false
				this.setAdditionalFields([])
				return connection
			})
		}
	}
}
</script>
